@media screen and (min-width: 1500px) and (max-width: 1728px) {
  .login-left img {
    padding: 4% 0 4% 0;
    width: 70%;
}
.img-conent h1{
  font-size: 30px;
}
}

@media screen and (min-width: 330px) and (max-width: 575px) {
  .login-page {
    padding: 0;
  }
  .welcome{
    font-size: 20px;
  }
  .login-col-right {
    height: 59vh;
  }
  .login-left img {
    width: 100%;
    padding: 5%;
  }
  .login-right {
    width: 335px;
  }
  .img-conent h4 {
    max-width: 340px;
    padding: 6% 0;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .login-page {
    padding: 0;
  }
  .login-col-right {
    height: 70vh;
  }
  .welcome{
    font-size: 22px;
  }
  .login-left img {
    width: 100%;
    padding: 5% 10%;
  }
  .login-right {
    width: 400px;
  }
  .img-conent h4 {
    max-width: 400px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .login-page {
    padding: 0;
  }

  .login-left img {
    padding: 10% 15%;
    width: 100%;
  }
  .login-col-right {
    height: 70vh;
  }
}


@media screen and (min-width: 992px) and (max-width: 1200px) {
  .login-col-right {
    height: 92vh;
  }
  .login-page {
    padding: 0;
  }

  .login-left img {
    /* padding: 10% 6%; */
    width: 55% !important;
  }
}

/* 14-7 */


/* 15-7 */

@media screen and (max-width: 1260px) {
  .dash-sidebar {
    position: fixed;
    z-index: 555;
    width: 99%;
    height: 99%;
    overflow: scroll;
  }
  .hamburger-close {
    font-size: 29px;
    position: absolute;
    top: 2%;
    right: 7%;
    display: block;
  }
}

/* 16-7 */
@media screen and (max-width: 575px) {
 
  .emp-search-in,.clear-filter button{
    padding: 13px 0;
  }
  .filter-position .dropdown .dropdown-btn{
padding: 13px 15%;
  }
  .date-range-input{
    height: 50px;
  }
  .bg-purplee{
    padding: 13px 10px;
  }
  .u_name{
    font-size: 15px;
  }
  .fs_10{
    font-size: 10px;
  }
}
@media screen and (min-width:992px) and (max-width:1500px) {
  .login-left img {
    padding: 4% 0 4% 0;
    width: 70%;
}
.img-conent {
    max-width: 460px;
}
.img-conent h1{
  font-size: 30px;
}
.login-page {
  padding: 2% 2% 0 2%;

}
}

.active {
  background-color: #8640df;
}

.call-off-text {
  font-size: 12px;
}
@media screen and (min-width:768px) {

  .call-postion-text{
    margin-left: 20%;
  font-size: 15px;
}
}
.call-postion-text{
  /* margin-left: 20%; */
font-size: 15px;
}