@import url("https://fonts.googleapis.com/css2?family=Bona+Nova&family=Poppins:wght@200;400;500;700&display=swap");
.app {
  display: grid;
  place-items: center;
}
a {
  text-decoration: none;
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #14181d;
  margin: 0;
  padding: 0;
  color: white !important;

  box-sizing: border-box;
}
.login-page {
  padding: 2%;
  max-width: 1920px;
  margin: auto;
}
.login-col-left {
  display: grid;
  place-items: center;
  place-content: center;
}
.login-left {
  text-align: center;
}
.img-conent {
  color: #ffffff;
  max-width: 501px;
  margin: auto;
}
.login-left img {
  padding: 10% 0 4% 0;
}
.img-conent h4 {
  color: #adadad;
  font-size: 16px;
  max-width: 408px;
  margin: auto;
  padding: 4% 0;
}
.login-right {
  width: 400px;
  margin: auto;
}
.login-right h2 {
  color: #ffffff;
  font-size: 40px;
  text-align: center;
}
.login-forgot {
  color: #8640df;
  font-size: 16px;
  margin-top: 4%;
  text-align: start;
}
.login-col-right {
  background-color: #2d333a;
  border-radius: 20px;
}
.login-coverd {
  color: #8640df;
}
.login-main-form {
  margin: auto;
  text-align: center;
}

.login-main-form input {
  max-width: 100%;
  width: 100%;
  padding: 4% 4% 4% 12%;
  margin-top: 15px;
  border-radius: 10px;
  border: none;
  color: #adadad;
}
.login-main-form input:focus {
  outline: none !important;
}
.login-col-right {
  height: 92vh;
  display: grid;
  place-content: center;
  place-items: center;
}

.login-btn {
  width: 100%;
  margin-top: 4%;
  background-color: #8640df;
  color: #ffffff;
  padding: 4% 0;
  border: none;
  border-radius: 10px;
}
.email-input {
  position: relative;
}
.email-input img {
  position: absolute;
  left: 3%;
  top: 46%;
}
/* dashboard */
.dashboard {
  max-width: 1920px;
  width: 100%;
  margin: auto;
}
.divider {
  border-top: 1px solid gray;
  width: 100%;
}

.dash-sidebar {
  max-width: 300px;
  width: 100%;
  border-radius: 10px;
  min-height: 98vh;
  /* height: 100%; */
  background-color: #2d333a;
}
.white {
  color: white !important;
}
.tab:hover {
  background-color: #8640df;
  border-radius: 10px;
  padding: 13px 15px;
}
.tab {
  /* background-color: #8640df; */
  border-radius: 10px;
  padding: 13px 15px;
}
/* .vector-img {
  height: 20px;
  width: 20px;
} */
.sidebar-inner {
  /* height: 70%; */
}
.dash-main {
  /* height: 100%; */
}
.side-tabs {
  /* height: 100%; */
}
.logo-side {
  height: 140px;
}

/* dashboard user top */
.dash-user-wrapper {
  border-bottom: 1px solid gray;
  height: 140px;
}
.user-avatar {
  border-radius: 50%;
}

/* dashboard user dropdrown */

.dropdown {
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);

  /* background-color: #8640df; */
}

.dropdown-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  color: #ffffff;
  justify-content: space-between;
  align-items: center;
}

.dropdown-body {
  padding: 5px;
  border-top: 1px solid #e5e8ec;
  display: none;
}

.dropdown-body.open {
  display: block;
}

.dropdown-item {
  padding: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-item-dot {
  opacity: 0;
  color: #91a5be;
  transition: all 0.2s ease-in-out;
}

.dropdown-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 13px;
  color: #91a5be;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

.icon.open {
  transform: rotate(90deg);
}

/* employee */
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #8640df !important;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: #5f666c !important;
}
.css-1yjjitx-MuiSwitch-track {
  background-color: #5f666c !important;
}

.filter-emp {
  display: flex;
  justify-content: space-between;
  padding: 2% 0;
}

/* .search-emp {
  position: relative;
}
.search-emp img {
  position: absolute;
  left: 5%;
  top: 30%;
}
.search-emp input {
  background-color: #2d333a;
  padding: 3% 0 3% 16%;
  color: #ffffff;
  border: none;
  width: 100%;
  border-radius: 5px;
}
.search-emp input:focus {
  outline: none;
} */
.switch-tgle {
  /* display: flex; */
  margin: auto 0;
  align-items: center;
}
.employee-dash {
  margin-top: 2%;
}

.App {
  font-family: sans-serif;
}

.filter-position .dropdown {
  margin: 0 auto;
  z-index: 100;
  /* width: 300px; */
  position: relative;
}
.filter-position .dropdown,
.dropdown * {
  z-index: 10;
}
.filter-position .dropdown .dropdown-btn {
  cursor: pointer;
  background: #2d333a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 12%;

  border-radius: 5px;
  color: white;
  font-weight: 500;
}
.filter-position .dropdown-content {
  position: absolute;
  padding-left: 12%;
  background: #2d333a;
  min-width: 200px;
  left: 0;
  width: 100%;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
}
.filter-position .dropdown-content .item {
  padding: 10px;
  cursor: pointer;
}
.filter-position .dropdown-content .item:hover {
  background: #2d333a;
}

.filter-position button {
  z-index: -1;
  display: block;
  width: 300px;
  margin: 0 auto;
}

/* / dashboard user dropdrown / */
.dropdown {
  position: relative;
}
.dropdown-header {
  background-color: #8640df;
  color: white;
  border-radius: 10px;
  cursor: pointer;
}
.dropdown-content {
  position: absolute;
  top: 55px;
  right: 0px;
  background-color: #44494f;
  box-shadow: 4px 4px 8px 0px #00000040;
  border-radius: 10px;
  padding: 20px;
}
.btn-content {
  padding: 5px 10px;
  width: 100%;
}
.btn-content:hover {
  background-color: #14181d;
  border-radius: 10px;
}

.border2 {
  border: 2px solid white;
  border-radius: 10px;
}

/* 13-7 */
.border-2 {
  border: 2px solid white;
}
.radius-10 {
  border-radius: 10px;
}
span.call-off\% {
  background-color: white;
  border-radius: 17px;
  color: black;
  padding: 4px 16px;
  font-size: 10px;
  font-weight: 600;
}
.call-off-number {
  font-size: 36px;
  color: #bb88fd;
  font-weight: 600;
}
.cl-time {
  font-size: 14px;
}
.donutchart-legend {
  display: none;
}
.donutchart-innertext-label,
.donutchart-innertext-value {
  display: none;
}
.doughtnut {
  position: relative;
}
.bg-purplee {
  background-color: #8640df;
  border-radius: 5px;
  padding: 10px;
  color: #ffffff;
  border: none;
}
.dash-clear {
  background-color: #a43737;
  border-radius: 5px;
  padding: 10px;
  color: #ffffff;
  border: none;
}
/* / sachin Today / */
.dropdown-contentMain {
  margin-top: 5%;
}
.dropdown {
  height: 100%;
}
.clear-filter button {
  background-color: #a43737;
  color: white;
  border: 0;
  height: 100%;
  padding: 4% 20px;
  width: 100%;
  border-radius: 5px;
}
.filter-position .dropdown .dropdown-btn {
  cursor: pointer;
  background: #2d333a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5% 10px 3%12%;
  height: 100%;
  border-radius: 5px;
  color: white;
  font-weight: 400;
}

.upload-employees {
  background: #2d333a;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 0 !important; */
  border-radius: 5px;
  /* padding: 3% 10px;
  font-weight: 400; */
}
.add-employees > div {
  background-color: #8640df;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0 !important;

  border-radius: 5px;
}
.css-t89xny-MuiDataGrid-columnHeaderTitle {
  /* color: white !important;  */
}
.css-204u17-MuiDataGrid-main {
  background-color: white;
}
/* 14-7 */
.rdrDateDisplayWrapper {
  display: none !important;
}
.date-range-input {
  /* position: relative; */
  background-color: #2d333a;
  border: none;
  color: white;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  outline: none;
}
.btn-primary {
  background-color: #2d333a !important;
  border-color: #2d333a !important;
}
/* .date-calander {
  position: absolute;

  top: 45px;
  left: 0;
  z-index: 555;
} */
.date-range-div {
  position: relative;
  height: 100%;
}
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  color: #8640df !important;
}
.rdrDayHovered {
  color: #8640df !;
}
.rdrDayToday .rdrDayNumber span:after {
  background: none !important;
}
.pointer {
  cursor: pointer !important;
}
.hamburger {
  position: absolute;
  top: 2%;
  font-size: 20px;
  cursor: pointer;
}
.doughnut-center {
  position: absolute;
  left: 61px;
  top: 27%;
  width: 60px;
}

.table,
tr {
  color: white !important;
  font-weight: 500 !important;
}
.dataTables_info {
  display: none !important;
}

.datatable-pagination {
  display: none !important;
}
.dataTables_paginate {
  display: none !important;
}
[data-test="table-foot"] {
  display: none !important;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 !important;
}
.table-sm > :not(caption) > * > * {
  padding: 15px !important;
  font-size: 16px !important;
}
tr {
  font-weight: 400 !important;
}
[data-test="table-body"] {
  background-color: #2d333a !important;
}

[data-test="datatable-head"] {
  background-color: #14181d !important;
  border: #14181d !important;
}

[data-test="datatable-table"] {
  background-color: #2d333a !important;
  background: #2d333a;
  border-radius: 15px;
  margin-top: 2%;
  padding-top: 2%;
}

[data-test="datatable-entries"] {
  display: none !important;
}
[data-test="datatable-search"] {
  display: none !important;
}

.dashboardcmp [data-test="datatable-table"] {
  margin-top: 0 !important;
  overflow-x: scroll;
}
.hamburger-close {
  font-size: 29px;
  position: absolute;
  top: 2%;
  display: none;
  right: 7%;
}
.dought-height {
  min-height: 193px;
}

.emp-search-in {
  background-color: #2d333a;
  border-radius: 5px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.emp-search input {
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  height: 100%;
}
.fs10 {
  font-size: 10px;
}
.btn-purple {
  background-color: #8640df;
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 10px 0;
}
/* 16-7 */
.upload_csv {
  border: 1px dashed black;
  border-radius: 10px;
  background-color: #ececec;
}
.fs14 {
  font-size: 14px;
}
.upload_success {
  background-color: #ececec;
  border-radius: 10px;
}
.date-emp {
  padding: 14px;
  border-radius: 3px;
  width: 100%;
  border: 1px solid #c1c1c1;
}
.react-datepicker-popper {
  z-index: 5 !important;
}
.date-emp:hover {
  border-color: #3b3b3b;
}
.date-emp:focus-visible {
  outline-color: #3890ea;
}
.react-datepicker-popper {
  z-index: 5;
}
.progress-bar {
  border-radius: 10px;
  height: 14px;
  background-color: #00ba00;
}
.upload_csv {
  border: 2px dashed #ccc;
  padding: 20px;
}

.upload_csv.dragover {
  border-color: #000;
}
/* / "sacin today css" /
/ width / */
::-webkit-scrollbar {
  width: 10px;
}

/* / Track / */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* / Handle / */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* / Handle on hover / */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* 18-7 */
.dept_multi {
  z-index: 5;
}
.css-13cymwt-control {
  padding: 7px !important;
}
@media screen and (max-width: 575px) {
  .table_main {
    overflow-x: scroll;
  }
}
.placeholder::placeholder {
  color: white;
}

.table_main {
  max-height: 534px;
  overflow-y: auto;
}
.table > :not(caption) > * > * {
  padding: 14px 0;
}
/* / 19-7 /  */
.input-11 {
  padding: 13.5px 14px;
}
.input-11:hover {
  border-color: black;
}

.input-11:focus-visible {
  outline-color: #3890ea !important;
  border: 2px solid #3890ea !important;
}
.form-control:focus {
  box-shadow: none !important;
}
._100done {
  position: relative;
}
.puraTotal {
  position: absolute;
  right: 9%;
  font-size: 13px;
  color: black;
  top: -16px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.tblMn {
  max-height: 100% !important;
  overflow: auto !important;
}

.blurFacility {
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ) !important; /* Adjust the opacity as needed */
  backdrop-filter: blur(
    8px
  ) !important; /* Adjust the blur strength as needed */
  z-index: 1040 !important;
}

.dropdown-container .dropdown-content {
  position: absolute;
  top: 55px;
  right: 0px;
  background-color: white;
  box-shadow: none;
  border-radius: 10px;
  padding: 20px;
}
.modal-header .btn-close {
  background-color: white;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-item,
.user-drop-menu {
  background-color: #2d333a !important;
  color: white !important;
}
.filter-items {
  border-radius: 5px;
}
.filter-items:hover {
  background-color: #8640df;
}
.focus-none:focus {
  box-shadow: none !important;
}

.user-drop {
  background-color: #8640df !important;
}
.dropdown-item.active:focus {
  background-color: none;
}

.hover-user:hover {
  background-color: #14181d;
}
.dropdown-item:focus {
  background-color: #2d333a !important;
}
.dropdown-item:active {
  text-decoration: none;
  background-color: #2d333a !important;
}

.user-drop-item:focus,
.user-drop-item:hover {
  background-color: #2d333a !important;
}
.dash-main-filter .dropdown-toggle::after {
  display: none;
}

.newcls{
  margin: auto;
    display: grid;
    place-items: center;
}